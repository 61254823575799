{
  "address": "9UZhEPPrzXSAScvZ2cDF1GgYGJTZrAcPUEwNGLvvu9Pr",
  "metadata": {
    "name": "elearn",
    "version": "0.1.0",
    "spec": "0.1.0",
    "description": "Created with Anchor"
  },
  "instructions": [
    {
      "name": "add_manager",
      "discriminator": [
        125,
        38,
        192,
        212,
        101,
        91,
        179,
        16
      ],
      "accounts": [
        {
          "name": "admin",
          "writable": true,
          "signer": true
        },
        {
          "name": "admin_proof",
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "admin"
              }
            ]
          }
        },
        {
          "name": "manager_key"
        },
        {
          "name": "manager_proof",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "manager_key"
              }
            ]
          }
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "manager_bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "create_batch",
      "discriminator": [
        159,
        198,
        248,
        43,
        248,
        31,
        235,
        86
      ],
      "accounts": [
        {
          "name": "manager",
          "writable": true,
          "signer": true
        },
        {
          "name": "manager_proof",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "manager"
              }
            ]
          }
        },
        {
          "name": "batch",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  98,
                  97,
                  116,
                  99,
                  104,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "manager"
              },
              {
                "kind": "account",
                "path": "manager_proof.batch_count",
                "account": "Manager"
              }
            ]
          }
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "batch_name",
          "type": "string"
        },
        {
          "name": "batch_bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "create_certificate",
      "discriminator": [
        238,
        189,
        143,
        29,
        100,
        80,
        70,
        10
      ],
      "accounts": [
        {
          "name": "manager",
          "writable": true,
          "signer": true
        },
        {
          "name": "manager_proof",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "manager"
              }
            ]
          }
        },
        {
          "name": "batch",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  98,
                  97,
                  116,
                  99,
                  104,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "manager"
              },
              {
                "kind": "account",
                "path": "batch.batch_num",
                "account": "Batch"
              }
            ]
          }
        },
        {
          "name": "certificate",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  99,
                  101,
                  114,
                  116,
                  105,
                  102,
                  105,
                  99,
                  97,
                  116,
                  101,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "batch"
              },
              {
                "kind": "account",
                "path": "batch.certificate_count",
                "account": "Batch"
              }
            ]
          }
        },
        {
          "name": "student_key"
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "start_date",
          "type": "u64"
        },
        {
          "name": "end_date",
          "type": "u64"
        },
        {
          "name": "complete_date",
          "type": "u64"
        },
        {
          "name": "certificate_bump",
          "type": "u8"
        },
        {
          "name": "student_name",
          "type": "string"
        },
        {
          "name": "student_grade",
          "type": "string"
        },
        {
          "name": "course_name",
          "type": "string"
        },
        {
          "name": "school_name",
          "type": "string"
        },
        {
          "name": "school_uri",
          "type": "string"
        },
        {
          "name": "issuer_name",
          "type": "string"
        },
        {
          "name": "issuer_role",
          "type": "string"
        },
        {
          "name": "issuer_uri",
          "type": "string"
        }
      ]
    },
    {
      "name": "initialize_manager",
      "discriminator": [
        79,
        37,
        249,
        89,
        35,
        188,
        238,
        111
      ],
      "accounts": [
        {
          "name": "master",
          "writable": true,
          "signer": true,
          "address": "JakevMAR7R4Evr4PZpTqNAb1KkVXAskzYohd1vxEUqj"
        },
        {
          "name": "manager_proof",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "master"
              }
            ]
          }
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "manager_bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "modify_manager",
      "discriminator": [
        99,
        219,
        194,
        56,
        177,
        219,
        24,
        68
      ],
      "accounts": [
        {
          "name": "admin",
          "writable": true,
          "signer": true
        },
        {
          "name": "admin_proof",
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "admin"
              }
            ]
          }
        },
        {
          "name": "manager_key"
        },
        {
          "name": "manager_proof",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  109,
                  97,
                  110,
                  97,
                  103,
                  101,
                  114,
                  45,
                  115,
                  101,
                  101,
                  100
                ]
              },
              {
                "kind": "account",
                "path": "manager_key"
              }
            ]
          }
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "target_permissions",
          "type": "u8"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "Batch",
      "discriminator": [
        156,
        194,
        70,
        44,
        22,
        88,
        137,
        44
      ]
    },
    {
      "name": "certificate",
      "discriminator": [
        202,
        229,
        222,
        220,
        116,
        20,
        74,
        67
      ]
    },
    {
      "name": "CertificateV1",
      "discriminator": [
        239,
        243,
        107,
        96,
        78,
        75,
        170,
        166
      ]
    },
    {
      "name": "Manager",
      "discriminator": [
        221,
        78,
        171,
        233,
        213,
        142,
        113,
        56
      ]
    }
  ],
  "types": [
    {
      "name": "Batch",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u16"
          },
          {
            "name": "manager_key",
            "type": "pubkey"
          },
          {
            "name": "certificate_count",
            "type": "u64"
          },
          {
            "name": "batch_num",
            "type": "u64"
          },
          {
            "name": "batch_name",
            "type": "string"
          },
          {
            "name": "batch_bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Certificate",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u16"
          },
          {
            "name": "batch_pda",
            "type": "pubkey"
          },
          {
            "name": "manager_key",
            "type": "pubkey"
          },
          {
            "name": "student_key",
            "type": "pubkey"
          },
          {
            "name": "complete_date",
            "type": "u64"
          },
          {
            "name": "certificate_num",
            "type": "u64"
          },
          {
            "name": "certificate_bump",
            "type": "u8"
          },
          {
            "name": "student_name",
            "type": "string"
          },
          {
            "name": "student_grade",
            "type": "string"
          },
          {
            "name": "course_name",
            "type": "string"
          },
          {
            "name": "school_name",
            "type": "string"
          },
          {
            "name": "school_uri",
            "type": "string"
          },
          {
            "name": "issuer_name",
            "type": "string"
          },
          {
            "name": "issuer_role",
            "type": "string"
          },
          {
            "name": "issuer_uri",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "CertificateV1",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u16"
          },
          {
            "name": "batch_pda",
            "type": "pubkey"
          },
          {
            "name": "manager_key",
            "type": "pubkey"
          },
          {
            "name": "student_key",
            "type": "pubkey"
          },
          {
            "name": "start_date",
            "type": "u64"
          },
          {
            "name": "end_date",
            "type": "u64"
          },
          {
            "name": "complete_date",
            "type": "u64"
          },
          {
            "name": "certificate_num",
            "type": "u64"
          },
          {
            "name": "certificate_bump",
            "type": "u8"
          },
          {
            "name": "student_name",
            "type": "string"
          },
          {
            "name": "student_grade",
            "type": "string"
          },
          {
            "name": "course_name",
            "type": "string"
          },
          {
            "name": "school_name",
            "type": "string"
          },
          {
            "name": "school_uri",
            "type": "string"
          },
          {
            "name": "issuer_name",
            "type": "string"
          },
          {
            "name": "issuer_role",
            "type": "string"
          },
          {
            "name": "issuer_uri",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "Manager",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u16"
          },
          {
            "name": "manager_key",
            "type": "pubkey"
          },
          {
            "name": "batch_count",
            "type": "u64"
          },
          {
            "name": "certificate_count",
            "type": "u128"
          },
          {
            "name": "permission_type",
            "type": "u8"
          },
          {
            "name": "manager_bump",
            "type": "u8"
          }
        ]
      }
    }
  ]
}